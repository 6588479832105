import React from "react"
import { Layout, Title, Contact, Contacts } from "../components/index"
import { Helmet } from "react-helmet"
import Favicon from "../img/favicon3.png"

export default () => (
  <div className="kontakt">
    <Layout>
      <Helmet>
        <title>
          Contact – four great titles are looking for a place on your shelf!
        </title>
        <meta
          name="title"
          content="Contact - Art Organization Creative House"
        />
        <meta
          name="description"
          content="Creative House is art organization founded in 2018, which deals with the publication and distribution of quality literary works, the production of original animated films and the organization and implementation of creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original art expressions and to expand the space of creative freedom. The founders and main implementers of the program of the Art Organization CREATIVE HOUSE are the multi-award winning writer and screenwriter Nena Lončar and the artist Marijan Lončar."
        />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      <Title
        title="Contact us"
        subtitle="We look forward to your contact with us!"
      ></Title>
      <Contacts>
        <Contact
          title="Book order"
          subtitle="Marijan Lončar"
          mail="marijanloncar.animation@gmail.com"
        />
        <Contact
          title="Literary encounters"
          subtitle="Nena Lončar"
          mail="nenaloncar5@gmail.com"
        />
      </Contacts>
    </Layout>
  </div>
)
